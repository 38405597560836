import { render, staticRenderFns } from "./extra-page.vue?vue&type=template&id=7a53700a&scoped=true&"
import script from "./extra-page.vue?vue&type=script&lang=js&"
export * from "./extra-page.vue?vue&type=script&lang=js&"
import style0 from "./extra-page.vue?vue&type=style&index=0&id=7a53700a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a53700a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KAutocompleteTechnologies from '@web/components/core/k-autocomplete-technologies'
import KButton from '@web/components/core/k-button'
import KInput from '@web/components/core/k-input'
import KLayout from '@web/components/core/k-layout'
import KProductTitle from '@web/components/core/k-product-title'
import KSelect from '@web/components/core/k-select'
import KTextarea from '@web/components/core/k-textarea'
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {KAutocompleteTechnologies,KButton,KInput,KLayout,KProductTitle,KSelect,KTextarea,VExpandTransition,VForm})
