<template>
    <k-layout>
        <header class="tw-bg-white tw-px-4">
            <div class="tw-relative tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg tw-py-12 md:tw-py-20">
                <img src="./img/extra.svg" class="tw-absolute tw-bottom-0 tw-right-0 tw-max-h-80 tw-hidden lg:tw-block tw-pointer-events-none" />

                <k-product-title :title="$t('extra.title')" />

                <h2 class="tw-mt-8 tw-heading-2" v-html="$t('extra.subtitles.your_idea')" />

                <p class="tw-max-w-lg tw-mt-8">
                    {{ $t('extra.descriptions.no_interested_position') }}
                </p>
            </div>
        </header>
        <div class="page">
            <div id="success-section"></div>
            <div v-if="formState === 'SUCCESS'" class="container">
                <div class="card card-submitted">
                    <img class="card-submitted__icons" src="./icons/ok.svg" alt="Hotovo" />
                    <h2 class="medium-heading-text">{{ $t('global.titles.sent') }}</h2>
                    <p class="px-8 mt-4 body-text">
                        {{ $t('extra.descriptions.will_be_in_touch') }}
                    </p>
                    <k-button id="ga_extra_navigate-home" href="/" class="mt-8" color="primary">
                        {{ $t('navigation.home') }}
                    </k-button>
                </div>
            </div>

            <div v-else class="container">
                <section class="page-content">
                    <main class="tw-bg-white tw-p-12">
                        <v-form v-model="valid" ref="form">
                            <form @submit.prevent="submitForm">
                                <h4 class="tw-heading-4">{{ $t('extra.subtitles.project_information') }}</h4>
                                <hr class="kod-hr" />

                                <div class="mt-6 form-grup">
                                    <k-input
                                        v-model="form.project.city"
                                        :rules="[formRules.required]"
                                        type="text"
                                        :title="$t('global.inputs.city')"
                                        :invalid="submitted && !form.project.city"
                                    >
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </k-input>

                                    <k-select
                                        v-model="form.project.startDate"
                                        :rules="[formRules.required]"
                                        :items="dates"
                                        :title="$t('extra.inputs.date_of_start')"
                                        :invalid="submitted && !form.project.startDate"
                                    >
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </k-select>
                                </div>

                                <k-autocomplete-technologies
                                    v-model="form.project.technologies"
                                    :rules="[formRules.required]"
                                    :items="allTechnologies"
                                    :title="$t('extra.subtitles.preferred_technologies')"
                                    :invalid="submitted && (form.project.technologies || []).length < 1"
                                    :showLimit="4"
                                    class="mt-8"
                                />

                                <h4 class="mt-14">{{ $t('extra.subtitles.information_about_you') }}</h4>
                                <hr class="kod-hr" />

                                <div class="mt-6 form-grup">
                                    <k-input
                                        v-model="form.contact.name"
                                        :rules="[formRules.required]"
                                        type="text"
                                        :title="$t('global.inputs.firstname_surname')"
                                        :invalid="submitted && !form.contact.name"
                                    >
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </k-input>
                                    <k-input
                                        v-model="form.contact.email"
                                        :rules="[formRules.required, formRules.email]"
                                        type="email"
                                        :title="$t('global.inputs.email')"
                                        :emptyNotice="submitted && !form.contact.email"
                                    >
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </k-input>
                                </div>

                                <div class="mt-8 form-grup">
                                    <k-input v-model="form.contact.phone" type="text" :title="$t('extra.inputs.phone_number_optional')" />

                                    <k-select
                                        v-model="form.contact.experiences"
                                        :items="experiences"
                                        :rules="[formRules.required]"
                                        :title="$t('global.inputs.experiences')"
                                        :invalid="submitted && !form.contact.experiences"
                                    >
                                        <template #message="{ message }">
                                            {{ $t(message) }}
                                        </template>
                                    </k-select>
                                </div>

                                <k-textarea class="mt-8" :title="$t('extra.inputs.question_or_additional_info')" v-model="form.contact.note" />

                                <hr class="kod-hr kod-hr--mt-8" />

                                <v-expand-transition>
                                    <div v-show="submitted && !isFormValid()" class="form-error-message">
                                        <p>
                                            {{ $t('global.form_errors.check_entered_information') }}
                                        </p>
                                    </div>
                                </v-expand-transition>

                                <div class="main-button-wrapper">
                                    <k-button
                                        id="ga_extra_submit"
                                        :loading="formState === 'PENDING'"
                                        type="submit"
                                        class="tw-mt-8 tw-w-full sm:tw-w-auto"
                                    >
                                        {{ $t('global.actions.send') }}
                                    </k-button>
                                </div>
                            </form>
                        </v-form>
                    </main>
                </section>
            </div>
        </div>
    </k-layout>
</template>

<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import responsiveMixin from "./../responsiveMixin";

export default {
    mixins: [responsiveMixin],
    created() {
        if(!this.isLogged) {
            const redirectAfterLoginTo = {
                name: "extra",
            };
            Cookies.set("redirect.after_login", JSON.stringify(redirectAfterLoginTo));
            this.$router.push({ name: 'auth-signup' });
        }
    },
    data() {
        const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

        return {
            valid: false,
            submitted: false,
            formRules: {
                required: v => !!v || 'global.form_rules.non_empty_field',
                email: v => (v && emailRe.test(v)) || 'global.form_rules.valid_email'
            },
            form: {
                project: {
                    city: null,
                    startDate: null,
                    technologies: null
                },
                contact: {
                    name: null,
                    email: null,
                    phone: null,
                    experiences: null,
                    note: null,
                }
            },
            pageObject: {
                headerObject: {
                    headerImage: "/web/extra/podstranka_extra.png",
                    heading: "Koderia Referral",
                    containerClass: "standard",
                    color: "white",
                    breadcrumbs: []
                }
            }
        };
    },
    computed: {
        isLogged() {
            return !!this.$store.state.AUTH.user;
        },
        dates: function () {
            return ["ASAP", ...this.getNext12MonthNamesWithYear(), this.$t('global.time.later')]
        },
        experiences: function () {
            return [
                this.$t('extra.junior_less_than_2_years'),
                this.$t('extra.medior_2_4_years'),
                this.$t('extra.senior_5_and_more')
            ]
        },
        formState: function() {
            return this.$store.state.EXTRA.formState;
        },
        profile() {
            return this.$store.state.USER.profile || null;
        },
        ...mapGetters("ENUMS", {
            allTechnologies: "allTechnologiesNames"
        })
    },
    methods: {
        isFormValid: function() {
            return this.valid
        },
        submitForm: function() {
            this.submitted = true;
            this.$refs.form.validate();

            if (!this.isFormValid()) {
                return;
            }

            this.$store.dispatch("EXTRA/SUBMIT_EXTRA", this.form);
            window.location.hash = "#extra-hotovo";
        },
        getNext12MonthNamesWithYear: function() {
            const now = new Date();
            let month = now.getMonth();
            let year = now.getFullYear();

            const names = [
                this.$t('global.time.january'),
                this.$t('global.time.february'),
                this.$t('global.time.march'),
                this.$t('global.time.april'),
                this.$t('global.time.may'),
                this.$t('global.time.june'),
                this.$t('global.time.july'),
                this.$t('global.time.august'),
                this.$t('global.time.september'),
                this.$t('global.time.october'),
                this.$t('global.time.november'),
                this.$t('global.time.december')
            ];

            const res = [];
            for (let i = 0; i < 13; ++i) {
                month += 1;
                if (month > 11) {
                    month = 0;
                    year += 1;
                }
                res.push(names[month] + " " + year);
            }
            return res;
        }
    },
    watch: {
        formState() {
            if (this.formState === "SUCCESS") {
                this.$nextTick(() => {
                    this.$vuetify.goTo("#success-section");
                });
            }
        },
        profile: {
            immediate: true,
            handler() {
                if (this.profile && this.profile.email) {
                    this.form.contact.email = this.profile.email;
                }

                if (this.profile && this.profile.firstName) {
                    if (this.profile.lastName) {
                        this.form.contact.name = this.profile.firstName + " " + this.profile.lastName;
                    } else {
                        this.form.contact.name = this.profile.firstName;
                    }
                }

                if (this.profile && this.profile.phoneNumber) {
                    this.form.contact.phone = this.profile.phoneNumber;
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
@import "@web/sass/variables.scss";

.kod-header-extra-text {
    @media screen and (min-width: 48rem) {
        h3,
        p {
            width: 29.125rem;
        }
    }
    .body-text {
        @include margin-top-multiplier(3);
    }
}

.figure,
.figure * {
    -webkit-user-drag: none;
    pointer-events: none;
    user-select: none;
}

.figure--intro {
    position: absolute;
    width: unset;
    bottom: 0;
    right: -1.5rem;
    @include -lg {
        display: none;
    }
}

.page {
    font-family: inherit;
    font-size: 1rem;
    color: $koderia-black;
    margin-bottom: 8rem;
}

.hero {
    min-height: 90vh;
    background-size: cover;
    background-color: $kod-black;
    background-position: right top;
    @media (max-width: 63.938rem) {
        background-position: center top;
    }
    background-image: url("/web/extra/extra_background/sm.jpg");
    @media (min-width: 64rem) {
        background-image: url("/web/extra/extra_background/md.jpg");
    }
    @media (min-width: 80rem) {
        background-image: url("/web/extra/extra_background/lg.jpg");
    }
    @media (min-width: 120rem) {
        background-image: url("/web/extra/extra_background/xl.jpg");
    }
}

.hero__content {
    @include padding-top-multiplier(16);
    @include padding-bottom-multiplier(8);
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.section {
    .container {
        padding: 4rem 0;
        position: relative;
        @media (max-width: 64rem) {
            padding: 4rem 0 2.5rem;
        }
    }
}

.section--intro {
    background: transparent linear-gradient(180deg, #ffffff 0%, #eeeef1 100%) 0% 0% no-repeat padding-box;

    .container {
        padding-top: 8rem;
    }

    @include lg {
        .container {
            padding-top: 9rem;
            padding-bottom: 7rem;
        }
    }
}

.section__title {
    font-size: 2rem !important;
    line-height: 2.625rem;
    font-family: "soleil", serif !important;
    font-weight: 500;
    br {
        display: block;
    }
}

.container--content {
    width: 100%;
    @include lg {
        max-width: 39rem;
    }
}

@media (min-width: 64rem) {
    .hero__content {
        margin-left: -1.5rem;
    }
}

@media (min-width: 80rem) {
    .hero__content {
        margin-left: -8rem;
    }
}

@media (min-width: 120rem) {
    .hero__content {
        margin-left: -16rem;
    }
}

.hero__title {
    color: white;
    font-size: 2.5em;
}

.hero__title--accent {
    color: #ff896f;
}

.hero__subtitle {
    color: white;
    font-weight: bold;
    font-size: 3.5rem;
    line-height: 4.625rem;
    max-width: 64.375rem;
    letter-spacing: 0;
    margin-top: 0.875rem;

    @media (max-width: 63.938rem) {
        font-size: 2rem;
        line-height: 2.625rem;
    }
}

.page-content {
    @include margin-top-multiplier(12);
    width: 100%;
    max-width: 51.5rem;
    margin: 0 auto;

    @media (max-width: 63.938rem) {
        @include margin-top-multiplier(3.5);
        max-width: 39rem;
    }
}

.form-grup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;

    @media (max-width: 63.938rem) {
        grid-template-columns: 1fr;
    }
}

.page-content__header {
    text-align: center;
    max-width: 31.25rem;
    margin: 0 auto;
}

main .card {
    @include margin-top-multiplier(6);

    @include padding-top-multiplier(7);
    @include padding-left-multiplier(8);
    @include padding-right-multiplier(8);
    @include padding-bottom-multiplier(4);

    @media (max-width: 63.938rem) {
        @include padding-top-multiplier(4);
        @include padding-left-multiplier(2);
        @include padding-right-multiplier(2);
        @include padding-bottom-multiplier(4);
    }
}

h2.heading-text {
    line-height: 0.75rem;
}

.footer {
    @include padding-top-multiplier(20);
}

.main-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-button {
    color: white;
    font-weight: bold;
    width: 10.125rem;
    height: 3rem !important;

    @include margin-top-multiplier(7);
    @include padding-left-multiplier(5);
    @include padding-right-multiplier(5);

    border-radius: 0.125rem;
}

.main-button--footer {
    margin-top: 2rem !important;
}

.card.card-submitted {
    @include margin-top-multiplier(5);
    @include margin-bottom-multiplier(24);

    @include padding-top-multiplier(5);
    @include padding-bottom-multiplier(5);
    max-width: 39rem;
    margin: 0 auto;
    text-align: center;
}
.card.card-submitted .main-button {
    @include margin-top-multiplier(4);
}

.card-submitted__icons {
    width: 4.5rem;
    height: 4.5rem;
}

.gradient-background {
    background: transparent linear-gradient(to right, #ff5f6d, #ff785f, #ff5f6d) center/200% auto no-repeat padding-box;
}
.gradient-background-text {
    background-image: linear-gradient(to right, #ff5f6d, #ff785f, #ff5f6d);
    background-size: 200% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mt-14 {
    @include margin-top-multiplier(7);
}

.mt-16 {
    @include margin-top-multiplier(8);
}

.gradient-animation {
    animation: bgAnimation 20s ease infinite;
    background-size: 200% auto;
}

@keyframes bgAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.kod-hr {
    margin-top: 0.5rem !important;
}

.kod-hr--mt-8 {
    margin-top: 2rem !important;
}

.mt-14 {
    margin-top: 3.5rem;
}
</style>
